import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router";
import { Home } from './PAGES/Home';
import 'react-library/src/App.css'
import { About } from './PAGES/About';
import { Projects } from './PAGES/Projects';
import { Programs } from './PAGES/Programs';
import { Apps } from './PAGES/Apps';
import { AppPolicies } from './PAGES/AppPolicies';
import { Courses } from './PAGES/Courses';
import { Course } from './PAGES/COURSES/Course';
import { CourseLogin } from './PAGES/COURSES/CourseLogin';
import { CourseLesson } from './PAGES/COURSES/CourseLesson';
import { CourseSignup } from './PAGES/COURSES/CourseSignup';
import { CourseForum } from './PAGES/COURSES/CourseForum';
import { Contact } from './PAGES/Contact';
import { CourseUpload } from './PAGES/COURSES/CourseUpload';
import { CoursePost } from './PAGES/COURSES/CoursePost';


const root = document.getElementById("root");

ReactDOM.createRoot(root).render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/apps" element={<Apps />} />
      <Route path="/app-policies" element={<AppPolicies />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/contact" element={<Contact />} />
      {/*  */}
      <Route path="/course-forum" element={<CourseForum />} />
      <Route path="/course-login" element={<CourseLogin />} />
      <Route path="/course-signup" element={<CourseSignup />} />
      <Route path="/courses/:courseId" element={<Course />} />
      <Route path="/courses/:courseId/:lessonId" element={<CourseLesson />} />
      {/*  */}
      <Route path="/course-upload-07251995" element={<CourseUpload />} />
      <Route path="/course-post-07251995" element={<CoursePost />} />

    </Routes>
  </BrowserRouter>
);
