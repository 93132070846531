import { GoHomeFill } from "react-icons/go";
import { FaLightbulb } from "react-icons/fa";
import { FaSchool } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { IoPhonePortrait } from "react-icons/io5";
import { FaICursor } from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";
import logo from '../IMAGES/logo.png'
import { useEffect, useState } from "react";
import { TfiLayoutGrid2Alt } from "react-icons/tfi";
import { TfiClose } from "react-icons/tfi";
import { Clickable } from './Clickable'
import { useLocation, useNavigate } from "react-router";
import { GoArrowUpRight } from "react-icons/go";

export function Navigation() {
    const navigate = useNavigate();
    const [showNav, setShowNav] = useState(false);
    const { pathname } = useLocation()


    // 
    return <div className="host white">
        <div className="separate-h p-sm">
            <Clickable onPress={() => {
                navigate('/')
            }}>
                <h1 className="text-size8 line-sm">Innovative<br />Internet<br />Creations</h1>
            </Clickable>
            <Clickable onPress={() => {
                setShowNav(!showNav)
            }}>
                <TfiLayoutGrid2Alt className="orange" size={32} />
            </Clickable>
        </div>

        {
            showNav && <div className="box fixed top bottom right height-100 bg-dark flex-column scroll-y border-l-1-gray" style={{ zIndex: 1000 }}>
                <Clickable classes={'p-v'} onPress={() => {
                    setShowNav(false);
                }}>
                    <div className="text-center">
                        <TfiClose size={24} />
                    </div>
                </Clickable>
                <br />
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/')
                }}>
                    <div className={`text-center ${pathname == '/' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={24} />
                            }
                            <p className="text-size5 white text-right">home</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/about')
                }}>
                    <div className={`text-center ${pathname == '/about' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/about' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">about us</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/projects')
                }}>
                    <div className={`text-center ${pathname == '/projects' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/projects' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">projects</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/programs')
                }}>
                    <div className={`text-center ${pathname == '/programs' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/programs' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">programs</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/courses')
                }}>
                    <div className={`text-center ${pathname == '/courses' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/courses' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">courses</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/apps')
                }}>
                    <div className={`text-center ${pathname == '/apps' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/apps' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">apps</p>
                        </div>
                    </div>
                </Clickable>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/contact')
                }}>
                    <div className={`text-center ${pathname == '/contact' && 'bg-orange'} relative box`} style={{ height: 60, minWidth: 100 }}>
                        <div className="absolute bottom right text-right p-sm">
                            {
                                pathname != '/contact' && <GoArrowUpRight style={{ display: 'block', marginLeft: 'auto' }} className="text-right" size={20} />
                            }
                            <p className="text-size5 white text-right">contact</p>
                        </div>
                    </div>
                </Clickable>
                <div className="expand-v">
                </div>
                <Clickable onPress={() => {
                    setShowNav(false);
                    navigate('/');
                }}>
                    <div className="text-center">
                        <img src={logo} style={{ height: 40, width: 40 }} />
                    </div>
                </Clickable>
            </div>
        }

    </div>
}