import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from '../UTILITIES/Spacer'
import logo from '../IMAGES/logo.png'
import { Footer } from "../UTILITIES/Footer";
import { Clickable } from "../UTILITIES/Clickable";
import { useNavigate } from "react-router";
import { Background, Background2 } from "../UTILITIES/Background";
import { useEffect } from "react";
import { BsChevronRight } from "react-icons/bs";

export function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Innovative Internet Creations'
    }, [])

    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main p">
            <div className="row">
                <div className="box p-md md2">
                    <h1 className="thin line-sm text-size22 spacing-sm">We create websites, apps, games, courses, and solutions, all designed for the future.</h1>
                    <br />
                    <p className="text-right gray text-size6 thin">Jesus Jimenez Santos<br />
                        CEO, Founder<br />
                        760.208.0335<br />
                        bagel.inbox@gmail.com</p>
                </div>
                <div className="md2 lg1 xl2 text-center">
                    <img src={logo} className="full-width contain" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* LATEST  */}
            <div className="">
                <h2 className="text-size18 thin">Newest on our agenda..</h2>
                <div className="row">
                    <div className="sticky top p md2 lg1 xl2">
                        <Clickable onPress={() => {
                            navigate('/courses')
                        }}>
                            <h2 className="normal orange text-size18 line-sm"><span className="gray text-size8">/1</span><br />Mobile App Development<br /> Course for Business Students</h2>
                        </Clickable>
                    </div>
                    <div className="thin md2 lg2 xl2">
                        <p className="">The Mobile Application Development course is designed for business students and individuals who may not plan to pursue careers in programming but want to bring their business ideas to life through mobile solutions. As part of the program, the Dart Supplementary Course teaches essential programming skills needed to enhance app functionality, such as coding button actions, making app development seamless and efficient. This unique course focuses on empowering students to create mobile applications that optimize operations, reduce costs, and improve efficiency, transforming innovative ideas into impactful, real-world solutions.</p>
                    </div>
                </div>
                <Spacer height={100} />
                <div className="row">
                    <div className="sticky top p md2 lg1 xl2">
                        <Clickable onPress={() => {
                            navigate(`/programs`)
                        }}>
                            <h2 className="normal orange text-size18 line-sm"><span className="gray text-size8">/2</span><br />Blocks To Bytes</h2>
                        </Clickable>
                    </div>
                    <div className="thin md2 lg2 xl2">
                        <p className="">Blocks to Bytes is an innovative program designed to teach students how to code anything by combining hands-on learning with real-world application. Unlike traditional coding programs that simplify concepts to the point of treating students like babies, Blocks to Bytes recognizes that children are smart, capable, and ready to tackle complex ideas. Students learn coding concepts by using physical blocks to map out the structure and schema of a computer program, providing a tangible way to visualize and understand programming logic. These block-based designs are then translated into real code, bridging the gap between abstract concepts and practical application. This unique approach empowers students to develop a deep understanding of coding while fostering creativity and problem-solving skills.</p>
                    </div>
                </div>
                <Spacer height={100} />
                <div className="row">
                    <div className="sticky top p md2 lg1 xl2">
                        <Clickable onPress={() => {
                            window.open('https://petgroomingedu.web.app', '_blank')
                        }}>
                            <h2 className="normal orange text-size18 line-sm"><span className="gray text-size8">/3</span><br />Pet Grooming Edu</h2>

                        </Clickable>
                    </div>
                    <div className="thin md2 lg2 xl2">
                        <p className="">San Diego Grooming Academy has partnered with Innovative Internet Creations to create a student-teacher portal designed for aspiring pet groomers and instructors looking to start their own grooming schools. This platform organizes lessons, homework, tests, and grading, making it easier for students to learn and instructors to teach. Myke Ross, the owner of San Diego Grooming Academy, developed the lessons, assignments, and tests available on the portal. With his experience in the pet grooming industry, Myke ensures that the content is practical and focused on providing students with the skills they need to succeed in their careers.</p>
                    </div>
                </div>
                <Spacer height={100} />
                <div className="row">
                    <div className="sticky top p md2 lg1 xl2">
                        <h2 className="normal orange text-size18 line-sm"><span className="gray text-size8">/4</span><br />Genki Pocket Manga</h2>
                    </div>
                    <div className="thin md2 lg2 xl2">
                        <p className="">Elevate your manga reading experience with a unique app that brings you an incredible collection of manga comics from independent artists and small studios in Japan, the US, and Mexico. Designed specifically for manga lovers, this app showcases art and stories from passionate creators, offering expertly formatted manga for mobile devices. Enjoy an easier, more immersive reading experience wherever you go, and connect with the creative voices behind the stories you love.</p>
                    </div>
                </div>
            </div>
            <Spacer />
            {/* UPDATES */}
            <div className="">
                <h2 className="text-size18 thin p-v">Latest updates..</h2>
                <div className="row gap">
                    <div className="bg-dark p box separate-v">
                        <div className="">
                            <p className="text-right text-size4 gray">Feb 9, 2025</p>
                            <br />
                            <p className="text-size5 thin">We’ve decided to launch our mobile app development course for free! You’ll have the opportunity to create up to five mobile apps at no cost. If you’re eager to learn more and expand your skills, you can unlock up to 30 app projects through our tiered plans. Sign up today and start building!</p>
                        </div>
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                navigate('/courses');
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                    <div className="bg-dark p box separate-v">
                        <div className="">
                            <p className="text-right text-size4 gray">Jan 4, 2025</p>
                            <br />
                            <p className="text-size5 thin">Our team has been working tirelessly to launch an innovative new course on mobile app development. Unlike traditional courses, ours is designed specifically for individuals who aren’t pursuing a career in programming. Instead, it’s tailored for those with business ideas, equipping them with the skills to create mobile solutions that streamline operations, reduce costs, and enhance efficiency.</p>
                        </div>
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                navigate('/courses');
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                    <div className="bg-dark p box separate-v">
                        <div>
                            <p className="text-right text-size4 gray">Dec 23, 2024</p>
                            <br />
                            <p className="text-size5 thin">Since 2015, we have been devising a perfect program for young students to learn all about programming; real programming with real results. We decided to approach a system which will teach students about programming concepts, but also teach mathematical, financial, algorithmic, and other fundamental concepts which are broken down and but to the test when studying programming. We are now introducing Blocks to Bytes.</p>
                        </div>
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                window.open('https://blockstobytes.com', '_blank')
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                    <div className="bg-dark p box separate-v">
                        <div>
                            <p className="text-right text-size4 gray">November 5, 2024</p>
                            <br />
                            <p className="text-size5 thin">Good news! We are just finishing a brand new application which allows businesses to post coupons and ads for the local community to see. We are appalled by the consistent increase in cost for ads and want to help small businesses get as much recognition and attention as any others in the area.</p>
                        </div>
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                window.open('https://adsmayhem.com', '_blank')
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
}